<template>
  <span class="text-h4 text-sm-h4 text-md-h3 text-lg-h3">
    <slot></slot>
  </span>
</template>

<script>
export default {
name: 'PageHeader',
}
</script>

<style scoped>

</style>
