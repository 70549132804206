<template>
  <v-container id="request">
    <v-row class="d-flex justify-center">
      <v-col cols="12" sm="11" md="11" lg="8">
        <page-header>{{ $t('contact.header') }}</page-header>
      </v-col>
    </v-row>

    <v-row class="d-flex justify-center">
      <v-col cols="12" sm="11" md="11" lg="8">
        <page-header-subtitle>{{ $t('contact.header_subtitle') }}</page-header-subtitle>
      </v-col>
    </v-row>

    <v-row class="py-10 d-flex justify-center">
      <v-col cols="12" sm="11" md="11" lg="8">
        <v-stepper v-model="step" vertical non-linear>
          <v-stepper-step step="1" :editable="stepEditable(1)" :color="getStepperColor(1)" :complete-icon="getStepperIcon(1)" :complete="!!getStepperIcon(1)" :rules="[() => !formError(1)]">
            <span>{{ $t('contact.about_you') }}</span>
          </v-stepper-step>

          <v-stepper-content step="1">
            <v-card>
              <v-container>
                <v-row>
                  <v-col cols="12" sm="6">
                    <v-text-field
                        prepend-inner-icon="mdi-account"
                        outlined
                        @input="$v.firstName.$touch()"
                        @blur="$v.firstName.$touch()"
                        @keyup.enter="nextStep(2)"
                        :error-messages="firstNameErrors"
                        v-model="firstName"
                        :counter="firstNameMaxLength"
                        required
                        :label="$t('contact.first_name')">
                    </v-text-field>
                  </v-col>

                  <v-col cols="12" sm="6">
                    <v-text-field
                        prepend-inner-icon="mdi-account"
                        outlined
                        @input="$v.lastName.$touch()"
                        @blur="$v.lastName.$touch()"
                        @keyup.enter="nextStep(2)"
                        :error-messages="lastNameErrors"
                        v-model="lastName"
                        :counter="lastNameMaxLength"
                        required
                        :label="$t('contact.last_name')">
                    </v-text-field>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" sm="6">
                    <v-autocomplete
                        prepend-inner-icon="mdi-earth"
                        outlined
                        @input="$v.country.$touch()"
                        @blur="$v.country.$touch()"
                        required
                        :error-messages="countryErrors"
                        v-model="country"
                        return-object
                        auto-select-first
                        item-value="name"
                        item-text="translatedName"
                        :items="countries"
                        :label="$t('contact.country')">
                      <template v-slot:selection="data">
                        <span>{{ data.item.translatedName }}</span>
                      </template>
                      <template v-slot:item="data">
                        <template v-if="typeof data.item !== 'object'">
                          <v-list-item-content v-text="data.item"></v-list-item-content>
                        </template>
                        <template v-else>
                          <v-list-item-avatar class="rounded-0" size="20" v-if="data.item.flag">
                            <img :src="data.item.flag">
                          </v-list-item-avatar>
                          <v-list-item-content>
                            <v-list-item-title v-html="data.item.translatedName"></v-list-item-title>
                          </v-list-item-content>
                        </template>
                      </template>
                    </v-autocomplete>
                  </v-col>

                  <v-col cols="12" sm="6">
                    <v-text-field
                        prepend-inner-icon="mdi-phone"
                        outlined
                        @input="$v.phone.$touch()"
                        @blur="$v.phone.$touch()"
                        @keyup.enter="nextStep(2)"
                        v-model="phone"
                        :error-messages="phoneErrors"
                        :counter="phoneMaxLength"
                        :label="$t('contact.phone')"
                        required>
                    </v-text-field>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12">
                    <v-text-field
                        prepend-inner-icon="mdi-email"
                        outlined
                        v-model="email"
                        @input="$v.email.$touch()"
                        @blur="$v.email.$touch()"
                        @keyup.enter="nextStep(2)"
                        :error-messages="emailErrors"
                        type="email"
                        :counter="emailMaxLength"
                        :label="$t('contact.email')"
                        required>
                    </v-text-field>
                  </v-col>
                </v-row>
              </v-container>

              <v-card-actions>
                <v-btn :disabled="!formValid(1)" color="primary" @click="nextStep(2)">{{ $t('contact.next_step') }}</v-btn>
              </v-card-actions>
            </v-card>
          </v-stepper-content>

          <v-stepper-step step="2" :editable="stepEditable(2)" :color="getStepperColor(2)" :complete-icon="getStepperIcon(2)" :complete="!!getStepperIcon(2)" :rules="[() => !formError(2)]">
            <span>{{ $t('contact.about_your_business') }}</span>
          </v-stepper-step>

          <v-stepper-content step="2">
            <v-card>
              <v-container>
                <v-row>
                  <v-col cols="12" sm="6">
                    <v-text-field
                        outlined
                        prepend-inner-icon="mdi-home"
                        @input="$v.company.$touch()"
                        @blur="$v.company.$touch()"
                        @keyup.enter="nextStep(3)"
                        :error-messages="companyErrors"
                        v-model="company"
                        :counter="companyMaxLength"
                        required
                        :label="$t('contact.business_name')">
                    </v-text-field>
                  </v-col>

                  <v-col cols="12" sm="6">
                    <v-autocomplete
                        outlined
                        prepend-inner-icon="mdi-home-group"
                        @input="$v.industry.$touch()"
                        @blur="$v.industry.$touch()"
                        @keyup.enter="nextStep(3)"
                        :error-messages="industryErrors"
                        v-model="industry"
                        return-object
                        item-value="name"
                        item-text="translatedName"
                        required
                        :items="industries"
                        :label="$t('contact.industry')">
                    </v-autocomplete>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12">
                    <v-text-field
                        outlined
                        prepend-inner-icon="mdi-account-multiple"
                        @input="$v.employees.$touch()"
                        @blur="$v.employees.$touch()"
                        @keyup.enter="nextStep(3)"
                        :error-messages="employeesErrors"
                        v-model="employees"
                        type="number"
                        min="0"
                        :hint="$t('contact.employees_hint')"
                        :max="employeesMaxValue"
                        :counter="employeesMaxLength"
                        required
                        :label="$t('contact.employees')">
                    </v-text-field>
                  </v-col>
                </v-row>
              </v-container>

              <v-card-actions>
                <v-btn color="primary" :disabled="!formValid(2)" @click="nextStep(3)">{{ $t('contact.next_step') }}</v-btn>
              </v-card-actions>
            </v-card>
          </v-stepper-content>

          <v-stepper-step step="3" :editable="stepEditable(3)" :color="getStepperColor(3)" :complete-icon="getStepperIcon(3)" :complete="!!getStepperIcon(3)" :rules="[() => !formError(3)]">
            <span>{{ $t('contact.message') }}</span>
            <small class="text-lowercase">{{ $t('contact.optional') }}</small>
          </v-stepper-step>

          <v-stepper-content step="3">
            <v-card>
              <v-container>
                <v-row>
                  <v-col cols="12">
                    <v-textarea
                        outlined
                        no-resize
                        @input="$v.message.$touch()"
                        @blur="$v.message.$touch()"
                        :error-messages="messageErrors"
                        :counter="messageMaxLength"
                        :label="$t('contact.message')"
                        v-model="message">
                    </v-textarea>
                  </v-col>
                </v-row>
              </v-container>

              <v-card-actions>
                <v-btn color="primary" :disabled="!formValid(3)" @click="nextStep(4)">{{ $t('contact.next_step') }}</v-btn>
              </v-card-actions>
            </v-card>
          </v-stepper-content>

          <v-stepper-step step="4" :editable="stepEditable(4)" :color="getStepperColor(4)" :complete-icon="getStepperIcon(4)" :complete="!!getStepperIcon(4)" :rules="[() => !formError(4)]">
            <span>{{ $t('contact.submit') }}</span>
          </v-stepper-step>

          <v-stepper-content step="4">
            <v-card>
              <v-container>
                <v-row>
                  <v-col cols="12" class="d-flex flex-column">
                    <span class="text-pre-wrap">{{ $t('contact.confirm_privacy') }}</span>
                    <router-link to="/privacy" target="_blank">{{ $t('main.privacy_policy') }}</router-link>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12">
                    <v-scroll-x-transition>
                      <v-sheet v-if="submitted" class="d-flex align-center rounded lighten-3 pa-6" color="green">
                        <v-icon class="mr-5 darken-2" size="35">mdi-check</v-icon>
                        <span class="text-pre-wrap">{{ $t('contact.submit_success_text') }}</span>
                      </v-sheet>
                    </v-scroll-x-transition>
                  </v-col>
                </v-row>
              </v-container>

              <v-card-actions v-if="!submitted">
                <v-btn color="primary" @click="submit()" :disabled="submitting" :loading="submitting">
                  <span>{{ $t('contact.submit') }}</span>
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-stepper-content>
        </v-stepper>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import PageHeader from '@/components/PageHeader';
import PageHeaderSubtitle from '@/components/PageHeaderSubtitle';
import axios from 'axios';
import {validationMixin} from 'vuelidate';
import {required, maxLength, email, numeric, minValue, maxValue} from 'vuelidate/lib/validators';

const firstNameMaxLength = 100;
const lastNameMaxLength = 100;
const phoneMaxLength = 20;
const emailMaxLength = 100;
const companyMaxLength = 100;
const employeesMaxLength = 10;
const messageMaxLength = 1000;

export default {
  name: 'Contact',
  components: {
    PageHeader,
    PageHeaderSubtitle,
  },
  mixins: [validationMixin],
  validations: {
    firstName: {
      required,
      maxLength: maxLength(firstNameMaxLength),
    },
    lastName: {
      required,
      maxLength: maxLength(lastNameMaxLength),
    },
    country: {
      required,
    },
    phone: {
      required,
      maxLength: maxLength(phoneMaxLength),
    },
    message: {
      maxLength: maxLength(messageMaxLength),
    },
    email: {
      required,
      email,
      maxLength: maxLength(emailMaxLength),
    },
    company: {
      required,
      maxLength: maxLength(companyMaxLength),
    },
    industry: {
      required,
    },
    employees: {
      required,
      numeric,
      minValue: minValue(0),
      maxValue: maxValue(999999999),
    },
  },
  computed: {
    employeesMaxValue() {
      return '9'.repeat(employeesMaxLength);
    },
    employeesErrors() {
      const errors = [];
      if (!this.$v.employees.$dirty) return errors;

      !this.$v.employees.required && errors.push(this.$t('contact.err_required'));
      !this.$v.employees.minValue && errors.push(this.$t('contact.err_min_value'));
      !this.$v.employees.maxValue && errors.push(this.$t('contact.err_max_value'));

      return errors;
    },
    firstNameErrors() {
      const errors = [];
      if (!this.$v.firstName.$dirty) return errors;

      !this.$v.firstName.required && errors.push(this.$t('contact.err_required'));
      !this.$v.firstName.maxLength && errors.push(this.$t('contact.err_max_length'));

      return errors;
    },
    lastNameErrors() {
      const errors = [];
      if (!this.$v.lastName.$dirty) return errors;

      !this.$v.lastName.required && errors.push(this.$t('contact.err_required'));
      !this.$v.lastName.maxLength && errors.push(this.$t('contact.err_max_length'));

      return errors;
    },
    messageErrors() {
      const errors = [];
      if (!this.$v.message.$dirty) return errors;

      !this.$v.message.maxLength && errors.push(this.$t('contact.err_max_length'));

      return errors;
    },
    countryErrors() {
      const errors = [];
      if (!this.$v.country.$dirty) return errors;

      !this.$v.country.required && errors.push(this.$t('contact.err_required'));

      return errors;
    },
    phoneErrors() {
      const errors = [];
      if (!this.$v.phone.$dirty) return errors;

      !this.$v.phone.required && errors.push(this.$t('contact.err_required'));

      return errors;
    },
    emailErrors() {
      const errors = [];
      if (!this.$v.email.$dirty) return errors;

      !this.$v.email.required && errors.push(this.$t('contact.err_required'));
      !this.$v.email.email && errors.push(this.$t('contact.err_email'));
      !this.$v.email.maxLength && errors.push(this.$t('contact.err_max_length'));

      return errors;
    },
    companyErrors() {
      const errors = [];
      if (!this.$v.company.$dirty) return errors;

      !this.$v.company.required && errors.push(this.$t('contact.err_required'));
      !this.$v.company.maxLength && errors.push(this.$t('contact.err_max_length'));

      return errors;
    },
    industryErrors() {
      const errors = [];
      if (!this.$v.industry.$dirty) return errors;

      !this.$v.industry.required && errors.push(this.$t('contact.err_required'));

      return errors;
    },
    totalSteps() {
      return Object.keys(this.steppers).length;
    },
    countries() {
      let t = this.$t.bind(this);
      let countries = [];

      function addCountries(region, countryArr) {
        countries.push({divider: true});
        countries.push({header: t(`contact.${region}`)});

        countryArr.forEach(c => {
          c.translatedName = t(`contact.${c.name.toLowerCase()}`)
        });

        countries = countries.concat(countryArr.sort((a, b) => a.translatedName.localeCompare(b.translatedName)));
      }

      // Other
      countries.push({name: 'other', translatedName: t(`contact.other`)});

      // Europe
      addCountries('europe', [
        {name: 'norway', flag: require('svg-country-flags/svg/no.svg')},
        {name: 'sweden', flag: require('svg-country-flags/svg/se.svg')},
        {name: 'denmark', flag: require('svg-country-flags/svg/dk.svg')},
        {name: 'germany', flag: require('svg-country-flags/svg/de.svg')},
        {name: 'france', flag: require('svg-country-flags/svg/fr.svg')},
        {name: 'switzerland', flag: require('svg-country-flags/svg/ch.svg')},
      ]);

      // North america
      addCountries('north_america', [
        {name: 'usa', flag: require('svg-country-flags/svg/us.svg')},
      ]);

      return countries;
    },
    industries() {
      return [
        'restaurant',
        'hotel',
        'retail',
        'healthcare',
        'other',
      ].map(i => {
        return {
          name: i,
          translatedName: this.$t(`contact.${i}`),
        }
      });
    }
  },
  data: () => ({
    submitting: false,
    submitted: false,
    firstNameMaxLength,
    lastNameMaxLength,
    phoneMaxLength,
    emailMaxLength,
    companyMaxLength,
    employeesMaxLength,
    messageMaxLength,
    maxStep: 1,
    step: 1,
    industry: '',
    country: '',
    firstName: '',
    lastName: '',
    message: '',
    phone: '',
    email: '',
    company: '',
    employees: '',
    steppers: {
      1: ['firstName', 'lastName', 'country', 'phone', 'email'],
      2: ['company', 'industry', 'employees'],
      3: ['message'],
      4: [],
    },
  }),
  methods: {
    nextStep(step) {
      if (!this.formValid(step - 1)) return;

      this.step = step;
      this.maxStep = Math.max(this.maxStep, step);
    },
    stepEditable(step) {
      if (this.submitting) return false;
      if (this.submitted) return false;
      if (this.maxStep < step) return false;

      return parseInt(this.step) !== step;
    },
    getStepperColor(stepperNr) {
      if (!this.formComplete(stepperNr)) return;
      if (stepperNr === this.totalSteps && !this.submitted) return;

      return 'green';
    },
    getStepperIcon(stepperNr) {
      if (!this.formComplete(stepperNr)) return;
      if (stepperNr === this.totalSteps && !this.submitted) return;

      return 'mdi-check';
    },
    formComplete(stepperNr) {
      return this.maxStep >= stepperNr && !this.formError(stepperNr) && this.formValid(stepperNr);
    },
    formError(stepperNr) {
      return this.steppers[stepperNr].map(f => this.$v[f]).some(f => f.$error);
    },
    formValid(stepperNr) {
      return this.steppers[stepperNr].map(f => this.$v[f]).every(f => !f.$invalid);
    },
    submit() {
      this.submitting = true;

      axios.post('https://www2.easyatwork.com/emailSend', {
        first_name: this.firstName,
        last_name: this.lastName,
        country: this.country.name,
        phone: this.phone,
        email: this.email,
        business_name: this.company,
        business_industry: this.industry.name,
        employees: this.employees,
        message: this.message,
      }).then(() => {
        this.submitting = false;
        this.submitted = true;
      }).catch(e => {
        alert(e);
      });
    },
  },
}
</script>

<style scoped>
</style>
