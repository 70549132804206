<template>
  <span class="text-subtitle-1 text-md-h6 font-weight-light">
    <slot></slot>
  </span>
</template>

<script>
export default {
name: 'PageHeaderSubtitle',
}
</script>

<style scoped>

</style>
